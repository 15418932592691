.progressbar-wrapper {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 5px;
}

.progressbar-wrapper ul {
    padding-left: 0 !important;
}

.progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 22px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
}

.progressbar li span {
    font-size: 16px;
}

.title_step {
    font-weight: bold;
    text-align: right;
}

.progressbar li:before {
    width: 60px;
    height: 60px;
    content: "";
    line-height: 60px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: #fff;
}

.progressbar li:after {
    width: 100%;
    height: 3px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 30px;
    left: -50%;
    z-index: 0;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active {
    color: green;
    font-weight: bold;
}

.progressbar li.active:before {
    border-color: #f00a30;
    background: #f10028;
}

.progressbar li.active+li:after {
    background-color: #f10028;
}

.progressbar li.active:before {
    background: #f10028;
    background-size: 60%;
}

.progressbar li::before {
    background: #fff;
    background-size: 60%;
}

.progressbar {
    counter-reset: step;
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
}

.progressbar-min li,
.progressbar-max li,
.progressbar-step li {
    list-style-type: none;
    list-style-type: none;
    width: 100%;
    float: left;
    font-size: 18px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #393939;
}

.progressbar-min li:before,
.progressbar-max li:before,
.progressbar-step li:before {
    width: 40px;
    height: 40px;
    content: "";
    line-height: 40px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    border-color: #f10028;
    background: #f10028;
}

.progressbar-min li:after {
    width: 50%;
    height: 3px;
    content: '';
    position: absolute;
    background-color: #f10028;
    top: 20px;
    left: 50%;
    z-index: 0;
}

.progressbar-step li:after {
    width: 100%;
    height: 3px;
    content: '';
    position: absolute;
    background-color: #f10028;
    top: 20px;
    left: 0%;
    z-index: 0;
}

.progressbar-max li:after {
    width: 50%;
    height: 3px;
    content: '';
    position: absolute;
    background-color: #f10028;
    top: 20px;
    left: 0%;
    z-index: 0;
}

.step-title {
    color: #f10028;
}

.promotion-container {
    position: relative;
    background-color: #fff;
    margin-left: 12vw;
    margin-right: 12vw;
    margin-bottom: 3vh;
    padding: 5vh;
    border: 3px solid #f26721;
    h1, h3, .text-small-cus, b, .text-light-green-main {
        color: #f26721 !important;
    }
}

.promotion-container:hover {
    background-color: #f26721;
    border: 3px solid transparent;
    h1, h3, .text-small-cus, b, .text-light-green-main {
        color: #fff !important;
    }
}