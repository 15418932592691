.agreement-body {
    height: 300px !important;
    // --scrollbar-size: 8px;
    // --scrollbar-thumb-color: #6fc26f;
    // --scrollbar-thumb-hover-color: #5cb85c;
    // --scrollbar-border-radius: 4px;
    // border: 1px solid gray;
}

.agreement-bg {
    background-color: #dcdcdc !important;
}

.btn-feels-main {
    background-color: #50d587 !important;
    color: #fff !important;
    border-radius: 50rem !important;
}

.btn-green {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    .btn-green:hover {
        color: #ffffff;
        background-color: #23863a !important;
        border-color: #23863a !important;
    }
}

img.d-example {
    max-width: 255px;
    max-height: 200px;
    padding: 5px;
    border: 2px dashed #e7e7ee;
}

#kt_scrolltop {
    svg {
        g {
            path {
                fill: #fff !important;
            }
            rect {
                fill: #fff !important;
            }
        }
    }
}

.required-text {
    color: rgb(255, 85, 85);
    margin-left: 2px;
}

.result-img {
    height: 250px !important;
}

.textarea-min-h {
    min-height: 100px !important;
}

.bg-subscriber {
    background-color: #cc99ff !important;
}

.bg-steper {
    background-color: #7d00fa !important;
}